import axios from 'axios'
import { saveAs } from 'file-saver'

// Live
const apiUrl = '/api/'

// Dev Local
// const apiUrl = 'https://app.wellcomefit.ch/api/'

// Dev Local local
//const apiUrl = 'http://192.168.0.232:4000/api/'

// LocalLocal Network
// const apiUrl = 'http://192.168.140.224:4000/api/'

function buildUrl(path) {
	return `${apiUrl}${path}`
}

function blobToBase64(blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.readAsDataURL(blob)
	})
}

function buildOptions(options = {}, signal = null) {
	if (!options.headers) {
		options.headers = {}
	}

	if (signal) options.signal = signal

	options.headers.Authorization = localStorage.getItem('token')
	return options
}

export default {
	downloadContractUpgradePDF(upgradeId) {
		let url = buildUrl('dashboard/c/print/' + upgradeId)


		axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important 
			}
		).then((response) => {
			if (window.flutter_inappwebview) {

				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						'vertragUpgrade'
					)
				}
			} else {
				saveAs(response.data, 'vertragUpgrade')
			}
		})
	},

	fetchAccountTransactionsForID(accountID, from = 'test', to = 'testii') {
		let url = 'https://app.wellcomefit.ch/api/tac/api/account/transactions/' + accountID + '/' + from + '/' + to
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAccountTransactionsForIDTESTING(accountID, from = 'test', to = 'testii') {
		let url = 'https://app.wellcomefit.ch/api/tactest/api/account/transactions/' + accountID + '/' + from + '/' + to
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAccountTransactionsForIDAdminTESTING(accountID, from = 'test', to = 'testii') {
		let url = 'https://app.wellcomefit.ch/api/tactestadmin/api/account/transactions/' + accountID + '/' + from + '/' + to
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMemberGroupByEmail(email) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membergroup/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateTACUserProfile(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/profile'
		return axios.post(url, { data }, buildOptions()).then((res) => res.data)
	},
	createTACContract(contract) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/contract/create'
		return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
	},
	fetchContractStats(data) {
		let url = buildUrl('contract/stats')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createTACTimestop(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/timestop/create'
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchCheckinsPerTAC(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/fetch/checkin/statistic'
		return axios.post(url, { data }, buildOptions()).then((res) => res.data)
	},
	getTACReasons(id, reason = 'IDLEPERIOD') {
		let url = 'https://app.wellcomefit.ch/api/tac/api/reasons/for/resort/' + id + '/' + reason
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveTACMembercategoriesSeperate(data) {
		let url = buildUrl('tac/api/membercategoryseperate')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	saveTACMembercategories(data) {
		let url = buildUrl('tac/api/membercategory')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchTACProfileByTACId(userId) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/profile/id/' + userId
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMembercategories() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membercategory'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMemberDetailsByTACId(tacId) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membership/id/' + tacId
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCurrentTACMemberDetailsByTACId(tacId) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membership/current/id/' + tacId
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACAllMembershipsByEmail(email) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membership/all/by/mail/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACAllMemberships() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membership/all/by/for/customer'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACProfileByEmail(email) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/profile/email/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	searchTACProfiles(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/profile/search'
		return axios.post(url, { searchValues: data }, buildOptions()).then((res) => res.data)
	},
	fetchTACMembershipByEmail(email) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/membership/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchTACMemberHasExistingContractByEmail() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/has/contract/membership'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMemberOwnContract() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/own/membership'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMembershipByEmailTESTING(email) {
		let url = 'https://app.wellcomefit.ch/api/tactest/api/membership/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACMembershipByEmailAdminTESTING(email) {
		let url = 'https://app.wellcomefit.ch/api/tactestadmin/api/membership/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchTACContractMapInfos(contract) {
		let url = buildUrl('tac/api/contract/map/infos')
		return axios.post(url, contract, buildOptions()).then((res) => res.data)
	},
	createCacheUpdateEntriesPackages(cache) {
		let url = buildUrl('tac/api/contract/map/cache/packages')
		return axios.post(url, cache, buildOptions()).then((res) => res.data)
	},
	createCacheUpdateEntryMembership(cache) {
		let url = buildUrl('tac/api/contract/map/cache/membership')
		return axios.post(url, cache, buildOptions()).then((res) => res.data)
	},

	testTacApi(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/'
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	addCommentContractUpgrade(data) {
		let url = buildUrl('dashboard/c/upgrade/status/comment')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateContractUpgradeStatus(data) {
		let url = buildUrl('dashboard/c/upgrade/status/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchUpgradeContracts(data) {
		let url = buildUrl('dashboard/c/upgrade/status')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},

	updateGFRecommendations(userId, recommendations) {
		let url = buildUrl('gfrecommendations/update')
		return axios.post(url, { userId, recommendations }, buildOptions()).then((res) => res.data)
	},
	fetchGFRecommendations(userId) {
		let url = buildUrl('gfrecommendations')
		return axios.post(url, { userId }, buildOptions()).then((res) => res.data)
	},
	fetchGFRecommendationsSelf() {
		let url = buildUrl('gfrecommendations')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createContractUpgrade(contractInfo) {
		//let url = buildUrl('dasupdateContractUpgradeStatusate')
		let url = buildUrl('dashboard/c/upgrade/create')
		return axios.post(url, contractInfo, buildOptions()).then((res) => res.data)
	},
	fetchRunningTACContract(customerId, local = true) {
		if (local) {
			let url = buildUrl('dashboard/c/upgrade/taccontract')
			return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
		} else {
			let url = 'https://app.wellcomefit.ch/api/dashboard/c/upgrade/taccontract'
			return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
		}
	},
	fetchPackageUpgradePossibilites(customerId, local = true) {
		if (local) {

			let url = buildUrl('dashboard/c/upgrade/possibilities')
			return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
		} else {
			let url = 'https://app.wellcomefit.ch/api/dashboard/c/upgrade/possibilities'
			return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
		}
	},
	fetchPackageUpgradePossibilitesForOwnCustomer() {
		let url = 'https://app.wellcomefit.ch/api/dashboard/c/upgrade/possibilities/for/own/user'
		return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
	},
	saveRoodyTACContractMap(data) {
		let url = buildUrl('dashboard/c/mapper')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchRoodyTACContractMap() {
		let url = buildUrl('dashboard/c/mapper')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchChangelogEvents() {
		let url = buildUrl('changelog')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteChangelogEvent(id) {
		let url = buildUrl('changelog/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	submitChangelogEvent(entry) {
		let url = buildUrl('changelog')
		return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
	},
	verifyEmailVerificationCode(data) {
		let url = buildUrl('user/email/verification/verify')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	startEmailVerification() {
		let url = buildUrl('user/email/verification/start')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchUserEmailVerificationStatus() {
		let url = buildUrl('user/email/verification/status')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchHealthcareRequests() {
		let url = buildUrl(`healthcare/requests`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	healthcareFetchUserContracts() {
		let url = buildUrl(`healthcare/options`)
		if (location.host.includes('local')) {
			url = (`https://app.wellcomefit.ch/api/healthcare/options`)
		}
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	requestHealthcareUnlock(data) {
		let url = buildUrl('healthcare/request/contract/' + data.id + '/' + data.year)
		return axios.post(url, data.contract, buildOptions()).then((res) => res.data)
	},
	updateTACContractPaidStatus(data) {
		let url = buildUrl('healthcare/update/contract/status')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	claimCourseExecutionHours(id) {
		let url = buildUrl('gf/courses/claim/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	downloadA4PDFGF(id) {
		let url = buildUrl('gf/post/pdf/' + id)
		axios.get(
			url, //your url 
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						'GroupFitness'
					)
				}
			} else {
				saveAs(response.data, 'GroupFitness')
			}
		})
	},
	createHealthcarePDF(data) {
		let url = buildUrl('healthcare/pdf')
		axios.post(
			url, //your url 
			data,
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						'HealthCareConfirmation'
					)
				}
			} else {
				saveAs(response.data, 'HealthCareConfirmation')
			}
		})
	},
	downloadHealthcarePDF(healthcareId) {
		let url = buildUrl('healthcare/pdf/' + healthcareId)
		axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						'HealthCareConfirmation'
					)
				}
			} else {
				saveAs(response.data, 'HealthCareConfirmation')
			}
		})
	},

	saveHealthcareFormEntry(entry) {
		let url = buildUrl('healthcare')
		return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
	},
	fetchStudioSignature() {
		let url = buildUrl('studio/signature')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveStudioSignature(signature) {
		let url = buildUrl('studio/signatures')
		return axios.post(url, { signature }, buildOptions()).then((res) => res.data)
	},
	fetchCompanyFiguresAccountPlan(studioId) {
		let url = buildUrl(`figures/plan/${studioId}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCompanyFiguresIncomeStatementByDate(date) {
		let url = buildUrl(`figures/statement/${date}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	importCompanyFiguresIncomeStatement(data) {
		let url = buildUrl('figures/statement')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	importCompanyFiguresAccountPlan(data) {
		let url = buildUrl('figures/import')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	uploadCompanyFiguresIncomeStatement(formData, studioId) {
		let url = buildUrl('figures/statement/' + studioId)
		return axios.post(url, formData, buildOptions({
			headers: {
				'Content-Type': formData.type,
				'Original-File-Name': formData.name,
			},
		})).then((res) => res.data)
	},
	uploadCompanyFiguresAccountPlan(formData, studioId) {
		let url = buildUrl('figures/plan/' + studioId)
		return axios.post(url, formData, buildOptions({
			headers: {
				'Content-Type': formData.type,
				'Original-File-Name': formData.name,
			},
		})).then((res) => res.data)
	},
	uploadCompanyDiscountList(formData, filename) {
		let url = buildUrl('dashboard/contract/companydiscountlist/' + filename)
		return axios.post(url, formData, buildOptions({
			headers: {
				'Content-Type': formData.type,
			},
		})).then((res) => res.data)
	},
	fetchAccountTransactionsForUser() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/user'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAccountTransactionsForUserTESTING() {
		let url = 'https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/test'
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACPaymentsForEmail(email) {
		let url = 'https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/admin/' + email
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchUserTACContractDetails() {
		let url = buildUrl('dashboard/contract/tac/user/self')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTACContractDetails(userId) {
		let url = 'https://app.wellcomefit.ch/api/dashboard/contract/tac/user/fetch/' + userId
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCompanyConfirmations() {
		let url = buildUrl('dashboard/company/confirmations/download')

		axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'xlsx',
						'Firmenrabattliste'
					)
				}
			} else {
				saveAs(response.data, 'Firmenrabattliste')
			}
		})
	},
	fetchRecentlyAcceptedContracts() {
		let url = buildUrl('dashboard/contracts/recently/accepted')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitTimePayloadForAdmin(payload) {
		let url = buildUrl('timetracker/faker')
		return axios.post(url, payload, buildOptions()).then((res) => res.data)
	},
	fetchAllTimeEntriesEmployee(date) {
		let url = buildUrl('timetracker/faker/' + date)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitTimeTrackerMonth(data) {
		let url = buildUrl('timetracker/month/submit')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	submitTimeTrackerDailyNote(data) {
		let url = buildUrl('timetracker/update/dailyNotes')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	selfSubmitTimetrackerMonth(month) {
		let url = buildUrl('timetracker/month/selfsubmit')
		return axios.post(url, month, buildOptions()).then((res) => res.data)
	},
	savePreSavedAttendancesToDB(attendances, user) {
		let url = buildUrl('timetracker/save/presaved/attendances/to/db')
		return axios.post(url, { attendances: attendances, user }, buildOptions()).then((res) => res.data)
	},
	updateTimeTrackerMonth(data) {
		let url = buildUrl('timetracker/month/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchTimestopFormText() {
		let url = buildUrl('timestopform/text/fetch')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTimestopPDF(id) {
		let url = buildUrl('timestopform/generate/' + id)

		axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]

					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						'Timestop'
					)
				}
			} else {
				saveAs(response.data, 'Timestop')
			}
		})
	},
	changeTimestopStatus(id, status) {
		let url = buildUrl('timestopform/change/status')
		return axios.post(url, { id, status }, buildOptions()).then((res) => res.data)
	},
	fetchOpenTimestops() {
		let url = buildUrl('timestopform/open')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTimestopsAdmin(status) {
		let url = buildUrl('fetch/timestops/admin/' + status)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveTimestopManual(steps) {
		let url = buildUrl('timestopform/manual')
		return axios.post(url, { steps }, buildOptions()).then((res) => res.data)
	},

	fetchTimestopManual() {
		let url = buildUrl('timestopform/manual')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchRecentTimestops() {
		let url = buildUrl('timestopform/recent')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllTimestopsForUser() {
		let url = buildUrl('timestopform/for/user')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllTimestopsForUserEmailAdmin(email) {
		let url = buildUrl('timestopform/for/user/email/admin/' + email)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllTimestopsForUserEmail() {
		let url = buildUrl('timestopform/for/user/email')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteTimestopById(id) {
		let url = buildUrl('timestopform/delete/by/id/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateTimeStopFormEntry(data) {
		let url = buildUrl('timestopform/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchDeclinedTimestops() {
		let url = buildUrl('timestopform/declined')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTimestopTextStudio() {
		let url = buildUrl('timestopform/text/fetch/studio')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateTimestopFormText(data) {
		let url = buildUrl('timestopform/text')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getLeadStatistics(data) {
		let url = buildUrl('leads/statistic')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getGFInstructorListExcel() {
		let url = buildUrl('gfdownloadinstructorlist')
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	getAllianceEntriesExcel() {
		let url = buildUrl('allianz/download')
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	getAllianzListEntry() {
		let url = buildUrl('allianz/status')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	enterAllianzList() {
		let url = buildUrl('allianz/enter')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchUserAttendanceStats(userId) {
		let url = buildUrl('fetch/userattendancestats/' + userId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCheckinPool(filter = false, lastUpdatedAt = null) {
		let url = `https://app.wellcomefit.ch/api/checkin/pool/entries?filter=${filter}`;

		// Füge den lastUpdatedAt-Parameter nur hinzu, wenn er definiert ist
		if (lastUpdatedAt) {
			url += `&lastUpdatedAt=${encodeURIComponent(lastUpdatedAt)}`;
		}

		return axios.get(url, buildOptions()).then((res) => res.data);
	},
	fetchAttendanceCustomers(studioID = 0) {
		let url = buildUrl('attendance/customers/' + studioID)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAttendanceTrainers(studioId = null) {
		let url = buildUrl('attendance/trainers')
		if (studioId) url = buildUrl('attendance/trainers/' + studioId)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	subscribeEmailSubscription() {
		let url = buildUrl('mails/subscribe')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	unsubscribeEmailSubscription() {
		let url = buildUrl('mails/unsubscribe')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchMembercardNewsletterInfo() {
		let url = buildUrl('membercard/mailchimp/info')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	subscribeMembercardNewsletter(data) {
		let url = buildUrl('membercard/mailchimp/signup')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	unsubscribeMembercardNewsletter() {
		let url = buildUrl('membercard/mailchimp/signoff')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	subscribeNewsletter(data) {
		let url = buildUrl('mailchimp/subscribe')
		return axios.post(url, { data }, buildOptions()).then((res) => res.data)
	},
	unsubscribeNewsletter() {
		let url = buildUrl('mailchimp/unsubscribe')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchNewsletterInfo() {
		let url = buildUrl('mailchimp/info')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteTeam(id) {
		let url = buildUrl('team/delete/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createTeam(data) {
		let url = buildUrl('team/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createNewAttendanceCorrection(data) {
		let url = buildUrl('attendance/correction/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateExistingAttendanceEntry(data) {
		let url = buildUrl('attendance/correction/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteAttendanceEntry(data) {
		let url = buildUrl('attendance/correction/delete')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	acceptAttendanceCorrection(data) {
		let url = buildUrl('attendance/correction/accept')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	rejectAttendanceCorrection(data) {
		let url = buildUrl('attendance/correction/reject')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchVacationDaysLeft() {
		let url = buildUrl('timetracker/vacation/days/left')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchHolidayEvents(data) {
		let url = buildUrl('timetracker/overview/management/events')
		return axios.post(url, { range: data.datePickerRange, studio: data.selectedStudioSingle, studioManager: data.studioManager }, buildOptions()).then((res) => res.data)
	},
	changeHolidayStatus(data) {
		let url = buildUrl('timetracker/holiday/management/change/' + data._id + '/' + data.status)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTimeTrackerDemandCorrection(selectedDate) {
		let url = buildUrl(`timetracker/demand/correction/entries/${selectedDate}`)
		return axios.get(url, buildOptions()).then((res) => res.data)

	},
	fetchManagementTimeEntries(selectedDate) {
		let url = buildUrl(`timetracker/management/entries/${selectedDate}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchOfficeTimeEntries(selectedDate, studio) {
		let url = buildUrl(`timetracker/office/entries/${selectedDate}/${studio}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchOfficeTimeEntriesForStudio(selectedDate, studio) {
		let url = buildUrl(`timetracker/office/entries/for/studio`)
		return axios.post(url, { selectedDate, studio }, buildOptions()).then((res) => res.data)
	},
	fetchTimetrackerCalendarEntries(dates, showCorrection) {
		let url = buildUrl(`timetracker/entries/calendar/${dates.start}/${dates.end}/${showCorrection}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTechnicTeamEvents(data) {
		let url = buildUrl(`timetracker/overview/technic/${data.dates.start}/${data.dates.end}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllTimetrackerCalendarEntries() {
		let url = buildUrl('timetracker/entries/calendar')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteTimetrackerEntryEmployee(id) {
		let url = buildUrl('timetracker/entry/employee/delete/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	deleteTimetrackerEntryOffice(id) {
		let url = buildUrl('timetracker/office/employee/delete/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	addTimetrackerEntryOffice(data) {
		let url = buildUrl('timetracker/office/employee/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateTimetrackerEntryOffice(data) {
		let url = buildUrl('timetracker/office/employee/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateTimetrackerEntryEmployee(data) {
		let url = buildUrl('timetracker/entry/employee/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	/*deleteSickEntryManagement(id) {
		let url = buildUrl('timetracker/management/sick/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	}, */
	createSickEntryManagement(data) {
		let url = buildUrl('timetracker/management/sick')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteSickEntry(data) {
		let url = buildUrl('timetracker/sick/delete')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createHolidayEntryEmployee(data) {
		let url = buildUrl('timetracker/holiday/employee/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchHolidayEntryEmployee() {
		let url = buildUrl('timetracker/holiday/employee/list')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchHolidayEntryEmployeeStudioManagement(studio, status) {
		let url = buildUrl(`timetracker/holiday/employee/management/${studio}/${status}`)
		return axios.get(url, buildOptions()).then((res) => res.data)

	},
	createTripControlEntryEmployee(data) {
		let url = buildUrl('timetracker/trip/employee/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createTimetrackerEntryEmployee(data) {
		let url = buildUrl('timetracker/entry/employee/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getTimetrackerEntriesAdmin(day) {
		let url = buildUrl(`timetracker/entry/employee/admin/fetch/${day}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchUserRightList() {
		let url = buildUrl('user/rightlist/all')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	async fetchLeadExcel(data) {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('leads/download/excel');
			try {
				const response = await axios({
					url,
					method: 'POST',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					},
					data: {
						data,
						// Other data properties if needed
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'leadStatistik');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},
	async downloadBLSList() {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('employee/bls');
			try {
				const response = await axios({
					url,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'BLS_AED');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},
	async fetchLeadCostExcel() {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('leads/costs/excel');
			try {
				const response = await axios({
					url,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'leadCosts');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},
	async downloadGFPivotExcel(datePickerRange) {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('tools/gf/pivot');
			try {
				const response = await axios({
					url,
					method: 'POST',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					},
					data: {
						datePickerRange,
						// Other data properties if needed
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'GF_Pivot');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},

	async downloadGFStatisticsExcel(datePickerRange) {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('tools/gf/statistik');
			try {
				const response = await axios({
					url,
					method: 'POST',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					},
					data: {
						datePickerRange,
						// Other data properties if needed
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'GF_Statistik');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},

	downloadWeeklyExcel(studioCode) {
		let url = buildUrl('ex/weeklyexcel/' + studioCode)
		axios({
			url, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {

				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler('blobToBase64Handler', base64.toString(), 'xlsx', 'Wochenansicht_' + studioCode)
				}
			} else {
				saveAs(response.data, 'Wochenansicht_' + studioCode)
			}
		})
	},
	fetchTayPayPaymentConfig(data) {
		let url = 'https://app.wellcomefit.ch/api/tac/pay/payment/configuration/paypage'
		return axios.post(url, data, buildOptions()).then((res) => res.data)

	},
	tacPayTestEmail() {
		let url = buildUrl('email/for/tac/testing')
		return axios.get(url, buildOptions()).then((res) => res.data)

	},
	fetchPaymentSubmit(data, isDetail, transactionData, merchant, resortID, email, profileID) {
		data.transactionData = transactionData
		data.merchantAccount = merchant
		data.resortID = resortID
		data.email = email
		data.profileID = profileID
		let url = 'https://app.wellcomefit.ch/api/tac/pay/payment/submit/' + isDetail
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	verifyAdyenWithDetails(transactionID, redirectResult, retryCounts) {
		//let url = 'https://app.wellcomefit.ch/api/tac/pay/payment/verify/details'
		let url = buildUrl('tac/pay/payment/verify/details')
		return axios.post(url, { transactionID, redirectResult, retryCounts }, buildOptions()).then((res) => res.data)
	},
	sendReminderEmail(timestop) {
		let url = buildUrl('timestops/send/reminder/email')
		return axios.post(url, timestop, buildOptions()).then((res) => res.data)
	},

	fetchPaymentsOverview(date) {
		let url = buildUrl('payment/overview/' + date)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deletePaymentSetting(id) {
		let url = buildUrl('payment/settings/delete/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	createPaymentSetting(paymentSetting) {
		let url = buildUrl('payment/settings/create')
		return axios.post(url, paymentSetting, buildOptions()).then((res) => res.data)
	},
	fetchAllPaymentSettings() {
		let url = buildUrl('payment/settings/all')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deletePayment(paymentId) {
		let url = buildUrl('payment/cancel/' + paymentId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	recreatePayment(paymentId) {
		let url = buildUrl('payment/recreate/' + paymentId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getPaymentsForUserSelf() {
		let url = buildUrl('payment/user')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getPaymentsForUser(userId) {
		let url = buildUrl('payment/user/' + userId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkPaymentSetting() {
		let url = buildUrl('payment/settings/check')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchPaymentStatus(transactionId) {
		let url = buildUrl('payment/status/' + transactionId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	initPayment(initData) {
		let url = buildUrl('payment/init')
		return axios.post(url, initData, buildOptions()).then((res) => res.data)
	},
	createPayment(paymentData) {
		let url = buildUrl('payment/create')
		return axios.post(url, paymentData, buildOptions()).then((res) => res.data)
	},
	markAllNotificationsAsRead() {
		let url = buildUrl('notifications/mark/all/as/read')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitAnamnese(formData) {
		let url = buildUrl('anamnesebogen/create-for-user')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	deleteMedicalHistory(id) {
		let url = buildUrl('anamnesebogen/new/delete')
		return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
	},
	getEmailAlreadyUsed(mail) {
		let url = buildUrl('user/check/mail/in/use')
		return axios.post(url, mail, buildOptions()).then((res) => res.data)
	},
	getAnamneseForStudio() {
		let url = buildUrl('anamnesebogen/for/studio')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAnamneseForUser(id) {
		let url = buildUrl('anamnesebogen/for/user')
		return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
	},
	getAnamneseNutrition() {
		let url = buildUrl('anamnesebogen/nutrition')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getAnamneseNutritionForUser(id) {
		let url = buildUrl('anamnesebogen/for/user/nutrition')
		return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
	},
	getAnamneseVariant(variant) {
		let url = buildUrl('anamnesebogen/variant/' + variant)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getAnamneseVariantForUser(id, variant) {
		let url = buildUrl('anamnesebogen/for/user/variant')
		return axios.post(url, { id: id, variant }, buildOptions()).then((res) => res.data)
	},
	createOrUpdateAnamneseQuestion(data) {
		let url = buildUrl('anamnesebogen/question/create-update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteAnamneseQuestion(id) {
		let url = buildUrl('anamnesebogen/question/delete')
		return axios.post(url, id, buildOptions()).then((res) => res.data)
	},
	createOrUpdateAnamnesePage(data) {
		let url = buildUrl('anamnesebogen/page/create-update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateAnamnesePageSorting(data) {
		let url = buildUrl('anamnesebogen/page/update/sorts')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteAnamnesePage(id) {
		let url = buildUrl('anamnesebogen/page/delete')
		return axios.post(url, id, buildOptions()).then((res) => res.data)
	},
	fetchAnamneseDashboardInfos() {
		let url = buildUrl('anamnese/management')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllowedAdminStudios() {
		let url = buildUrl('dashboard/studios')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllowedTeams() {
		let url = buildUrl('dashboard/teams')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllowedTeamStudios() {
		let url = buildUrl('dashboard/teams/for/studio')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchGroupfitnessDashboardInfos(range) {
		let url = buildUrl('dashboard/gf/infos')
		return axios.post(url, range, buildOptions()).then((res) => res.data)
	},
	fetchTrainerDashboardInfos(range) {
		let url = buildUrl('dashboard/trainer/infos')

		return axios.post(url, range, buildOptions()).then((res) => res.data)
	},
	fetchTrainerDashboardInfosSingleTrainer(data) {
		let url = buildUrl('dashboard/trainer/infos/single/trainer')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchTrainerDashboardInfosStudio(data) {
		let url = buildUrl('dashboard/trainer/infos/studio')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchAnamneseDashboardQuestions(data) {
		let url = buildUrl('dashboard/anamnese/quuestions')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchDashboardUserStatisticsNew(studios) {
		let url = buildUrl('dashboard/user/statistics/new')
		return axios.post(url, studios, buildOptions()).then((res) => res.data)
	},
	fetchDashboardUserStatistics(studios) {
		let url = buildUrl('dashboard/user/statistics')
		if (location.host.includes('local')) {
			url = buildUrl('dashboard/user/statisticsnew')
		}
		return axios.post(url, studios, buildOptions()).then((res) => res.data)
	},
	fetchContractDevelopment(months) {
		let url = buildUrl('dashboard/contracts/development')
		return axios.post(url, { months }, buildOptions()).then((res) => res.data)
	},
	fetchDashboardUserTACStatistics(studios) {
		let url = ''
		if (location.host.includes('localhost')) {
			url = buildUrl('dashboard/user/tacstatisticsnew')
		} else {
			url = buildUrl('dashboard/user/tacstatistics')
		}

		return axios.post(url, studios, buildOptions()).then((res) => res.data)
	},
	getCustomerContracts(userId) {
		let url = buildUrl(`dashboard/user/contracts/${userId}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCustomerDocuments(userId) {
		let url = `https://app.wellcomefit.ch/api/dashboard/user/documents/${userId}`
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchDashboardUserInfos() {
		let url = buildUrl('dashboard/user/infos')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updatePaymentOptionsContract(info) {
		let url = buildUrl('dashboard/contract/paymentoptions/edit')
		return axios.post(url, info, buildOptions()).then((res) => res.data)
	},
	addAttachmentToContract(info) {
		let url = buildUrl('dashboard/contract/attachment/add')
		return axios.post(url, info, buildOptions()).then((res) => res.data)
	},
	fetchContractUserInfos(data) {
		let url = 'https://app.wellcomefit.ch/api/dashboard/contracts/infos'
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchContractsDevelopment(data) {
		let url = buildUrl('dashboard/contracts/development')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchDashboardCheckinInfos(range, selectedStudios) {
		let url = buildUrl('dashboard/checkin/infos')
		return axios.post(url, { range, selectedStudios }, buildOptions()).then((res) => res.data)
	},
	sendPushToTopic(notification) {
		let url = buildUrl('notifications/topic')
		return axios.post(url, notification, buildOptions()).then((res) => res.data)
	},
	fetchSlimMeUsers() {
		let url = buildUrl('user/slim-me')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getWorkoutFeedback() {
		let url = buildUrl('workout/feedback')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getWorkoutFeedbackLatest() {
		let url = buildUrl('workout/feedback/latest')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchExternalPackages(memberships) {
		let url = buildUrl('dashboard/contract/external/packages')
		return axios.post(url, memberships, buildOptions()).then((res) => res.data)
	},
	updateContractEditMode(infos) {
		let url = buildUrl('dashboard/contract/editmode')
		return axios.post(url, infos, buildOptions()).then((res) => res.data)
	},
	changeContractPublishedStatus(contract) {
		let url = buildUrl('dashboard/contract/published')
		return axios.post(url, contract, buildOptions()).then((res) => res.data)
	},
	createContractSettings(contract) {
		let url = buildUrl('dashboard/settings/contract')
		return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
	},
	fetchUserContract(info) {
		let url = buildUrl('dashboard/contract/user/fetch/' + info.userId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	downloadImage(imgUrl) {
		let title = "bild"
		let url = imgUrl
		axios({
			url, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'png',
						title.replaceAll(' ', '_').replaceAll('.', '_')
					)
				}
			} else {
				saveAs(response.data, title.replaceAll(' ', '_').replaceAll('.', '_'))
			}
		})
	},
	getIframeprintUserContract(userId, index, title = "Vertrag") {
		let url = buildUrl('dashboard/contract/print/' + userId + "/" + index)
		return axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((res) => res.data)
	},
	printUserContract(userId, index, title = "Vertrag") {
		let url = buildUrl('dashboard/contract/print/' + userId + "/" + index)

		axios.get(
			url, //your url
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}
		).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						title.replaceAll(' ', '_').replaceAll('.', '_')
					)
				}
			} else {
				saveAs(response.data, title.replaceAll(' ', '_').replaceAll('.', '_'))
			}
		})
	},
	createTemporaryUserContract(contract) {
		let url = buildUrl('dashboard/contract/user/create/temporary')
		return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
	},
	createUserContractSelf(contract) {
		let url = buildUrl('dashboard/contract/user/create/self')
		return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
	},
	createUserContract(contract) {
		let url = buildUrl('dashboard/contract/user/create')
		return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
	},
	updateUserContract(data) {
		let url = buildUrl('dashboard/contract/user/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	declineContract(data) {
		let url = buildUrl('dashboard/contract/user/status/decline')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateContractStatus(data) {
		let url = buildUrl('dashboard/contract/user/status/update')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteTemporaryContract(info) {
		let url = buildUrl('dashboard/contract/temporary/delete')
		return axios.post(url, info, buildOptions()).then((res) => res.data)
	},
	deleteContract(info) {
		let url = buildUrl('dashboard/contract/delete')
		return axios.post(url, info, buildOptions()).then((res) => res.data)
	},
	createContract(contract, internalTitle, eMails, promotions, enabledStudios, preConditionText, postConditionText, useStudioCity, badge) {
		let url = buildUrl('dashboard/contract/create')
		return axios.post(url, { contract, internalTitle, eMails, promotions, enabledStudios, preConditionText, postConditionText, useStudioCity, badge }, buildOptions()).then((res) => res.data)
	},
	fetchContract(contractId) {
		let url = buildUrl('dashboard/contracts/' + contractId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchContractsByStatus(status) {
		let url = buildUrl('dashboard/contracts/user/status/' + status)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchContractsPublic(id) {
		let url = buildUrl('dashboard/contractspublic/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchContracts() {
		let url = buildUrl('dashboard/contracts')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveContractSettings(data, fieldName) {
		let url = buildUrl('dashboard/settings/contract/' + fieldName)
		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},
	fetchContractSettings() {
		let url = buildUrl('dashboard/settings/contract')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateLead(lead) {
		let url = buildUrl('leads')
		return axios.put(url, lead, buildOptions()).then((res) => res.data)
	},
	fetchAllLeads(status) {
		let url = buildUrl('leads/all')
		return axios.put(url, { status }, buildOptions()).then((res) => res.data)
	},
	updateLeadCost(id, newWeeklyCost) {
		let url = buildUrl('leads/costs/settings/update/' + id)
		return axios.put(url, newWeeklyCost, buildOptions()).then((res) => res.data)
	},
	fetchLeadCostsSettings() {
		let url = buildUrl('leads/costs/settings')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchLeads(id, status) {
		let url = buildUrl('leads/' + id)
		return axios.put(url, { status: status }, buildOptions()).then((res) => res.data)
	},
	fetchReferralSettingsPublic() {
		let url = buildUrl('rleads/public')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchLeadSettingsPublic(link) {
		let url = buildUrl('leads/public/' + link)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createReferralLeadPublic(leadSettingsId, lead, studio, referralEmail) {
		let url = buildUrl('rleads/public')
		return axios.post(url, { leadSettingsId, lead, studio, referralEmail }, buildOptions()).then((res) => res.data)
	},
	createWalkin(lead) {
		let url = buildUrl('rleads/walkin/create')
		return axios.post(url, lead, buildOptions()).then((res) => res.data)
	},
	createLeadPublic(link, data) {
		let url = buildUrl('leads/public/' + link)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	saveReferralSetting(studio) {
		let url = buildUrl('leads/referral/settings')
		return axios.post(url, studio, buildOptions()).then((res) => res.data)
	},
	saveLeadSetting(leadSetting) {
		let url = buildUrl('leads/settings')
		return axios.post(url, leadSetting, buildOptions()).then((res) => res.data)
	},
	fetchLeadSettings() {
		let url = buildUrl('leads/settings')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchLeadsByCategory(id) {
		let url = buildUrl('leads/category/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveLeadCategory(category) {
		let url = buildUrl('leads/categories')
		return axios.post(url, category, buildOptions()).then((res) => res.data)
	},
	fetchLeadCategories() {
		let url = buildUrl('leads/categories')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchLeadOverview() {
		let url = buildUrl('leads/overview')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchReferralOverview(status) {
		let url = buildUrl('rleads/overview')
		return axios.put(url, { status }, buildOptions()).then((res) => res.data)
	},
	fetchDaycareSignups(date) {
		let url = buildUrl('daycare/signups/' + date)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchDaycareStatistic() {
		let url = buildUrl('daycare/signups/statistic')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	signupDaycareAdmin(data) {
		let url = buildUrl('businesshours/daycare/admin/signup')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	signupDaycare(data) {
		let url = buildUrl('businesshours/daycare/signup')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchBusinessHours(type) {
		let url = buildUrl('businesshours/' + type)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateBusinessHours(data) {
		let url = buildUrl('businesshours')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	setUserBirthday(date) {
		let url = buildUrl('user/birthday')
		return axios.post(url, { date }, buildOptions()).then((res) => res.data)
	},
	sendFeedbackBox(feedbackText, category, courseId = null) {
		let url = buildUrl('feedbackbox')
		return axios.post(url, { text: feedbackText, category, courseId }, buildOptions()).then((res) => res.data)
	},
	fetchCustomerInteractions(userId) {
		let url = buildUrl('employee/interactions/' + userId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveCustomerInteraction(entry) {
		let url = buildUrl('employee/interaction')
		return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
	},
	fetchWebsiteContent(code) {
		let url = buildUrl('website/content/' + code)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateWebsiteContent(content) {
		let url = buildUrl('website/content')
		return axios.post(url, { content }, buildOptions()).then((res) => res.data)
	},
	updateJob(job) {
		let url = buildUrl('website/job')
		return axios.post(url, job, buildOptions()).then((res) => res.data)
	},
	fetchJobs() {
		let url = buildUrl('website/jobs')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createEmployee(user) {
		let url = buildUrl('employee/website')
		return axios.post(url, { user }, buildOptions()).then((res) => res.data)
	},

	deleteEmployee(id, studio) {
		let url = buildUrl('employee/website/' + id + '/' + studio)

		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	updateEmployee(user) {
		let url = buildUrl('employee/website')
		return axios.post(url, { user }, buildOptions()).then((res) => res.data)
	},
	fetchAllEmployee(studios) {
		let url = buildUrl('employee/fetch/all')
		return axios.post(url, studios, buildOptions()).then((res) => res.data)
	},
	updateEmployeeSorting(data) {
		let url = buildUrl('employee/update/sorting')
		return axios.put(url, { data }, buildOptions()).then((res) => res.data)
	},
	updateEmployee(data) {
		let url = buildUrl('employee')
		return axios.put(url, { data }, buildOptions()).then((res) => res.data)
	},
	sendReferral(data) {
		let url = buildUrl('referrals')
		return axios.post(url, data).then((res) => res.data)
	},
	updateUserInfo(data) {
		let url = buildUrl('user/update/info')
		return axios.put(url, { data }, buildOptions()).then((res) => res.data)
	},
	addTargetWeightToUser(weight) {
		let url = buildUrl('user/add/targetWeight')

		return axios.put(url, { weight: weight }, buildOptions()).then((res) => res.data)
	},
	addNewWeightToUser(weight) {
		let url = buildUrl('user/add/weight')

		return axios.put(url, { weight: weight }, buildOptions()).then((res) => res.data)
	},
	signupMailCampaign(signup) {
		let url = buildUrl('mails/campaign/signup')
		return axios.post(url, { signup }, buildOptions()).then((res) => res.data)
	},
	fetchMailCampaigns() {
		let url = buildUrl('mails/campaign')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createMailCampaign(campaign) {
		let url = buildUrl('mails/campaign')
		return axios.post(url, { campaign }, buildOptions()).then((res) => res.data)
	},
	fetchSlides() {
		let url = buildUrl('slides')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchMainSlider() {
		let url = buildUrl('main/slider')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveSlide(slide) {
		let url = buildUrl('slide')
		return axios.post(url, { slide }, buildOptions()).then((res) => res.data)
	},
	savePopup(popup) {
		let url = buildUrl('notifications/popup')
		return axios.post(url, { popup }, buildOptions()).then((res) => res.data)
	},
	followLinkPopup(id) {
		let url = buildUrl('notifications/popup/lead/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	readPopup(id) {
		let url = buildUrl('notifications/popup/read/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchPopups() {
		let url = buildUrl('notifications/popups')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchPopup() {
		let url = buildUrl('notifications/popup')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchQRCode(link) {
		let url = buildUrl('qrcode/image?data=' + link)
		axios({
			url, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			if (window.flutter_inappwebview) {
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler('blobToBase64Handler', base64.toString(), 'png', 'qrCode')
				}
			} else {
				saveAs(response.data, 'qrCode')
			}
		})
	},
	fetchGFWeeklyPDF(id) {
		let url = buildUrl('ex/weeklypdf/' + id)
		axios({
			url, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler('blobToBase64Handler', base64.toString(), 'pdf', 'Wochenansicht')
				}
			} else {
				saveAs(response.data, 'Wochenansicht')
			}
		})
	},

	fetchPlanPDF(plan) {
		let url = buildUrl('plan/pdf/' + plan._id)

		axios({
			url, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'pdf',
						plan.form.title.replaceAll(' ', '_').replaceAll('.', '_')
					)
				}
			} else {
				saveAs(response.data, plan.form.title.replaceAll(' ', '_').replaceAll('.', '_'))
			}
		})
	},
	readNotification(id) {
		let url = buildUrl('notifications/read/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchNotifications() {
		let url = buildUrl('notifications')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updatePushToken(token) {
		let url = buildUrl('push/token')
		return axios.post(url, token, buildOptions()).then((res) => res.data)
	},
	fetchTerminationsForStudio(data) {
		let url = buildUrl('studio/fetch/terminations')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},

	updateStudioTerminations(studio) {
		let url = buildUrl('studio/update/terminations')
		return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
	},

	updateStudioVacationDays(studio) {
		let url = buildUrl('studio/update/vacaction-days')
		return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
	},

	updateTeamVacationDays(team) {
		let url = buildUrl('team/update/vacaction-days')
		return axios.put(url, { team }, buildOptions()).then((res) => res.data)
	},

	updateStudioGrowth(studio) {
		let url = buildUrl('studio/update/growth')
		return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
	},
	updateStudioCorrection(studio) {
		let url = buildUrl('studio/update/correction')
		return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
	},
	updateStudio(studio) {
		let url = buildUrl('studio')
		return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
	},
	fetchWeeklyCourseList() {
		let url = buildUrl('ex/gf')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchExCourseList(code) {
		let url = buildUrl('ex/gf/' + code)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteGFRoom(id) {
		let url = buildUrl('gf/rooms/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	updateGFRoom(obj) {
		let url = buildUrl('gf/rooms')
		return axios.put(url, obj, buildOptions()).then((res) => res.data)
	},
	createGFRoom(obj) {
		let url = buildUrl('gf/rooms')
		return axios.post(url, obj, buildOptions()).then((res) => res.data)
	},
	fetchGFRooms() {
		let url = buildUrl('gf/rooms')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchExerciseTags() {
		let url = buildUrl('exercise/tags')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteGFLabel(id) {
		let url = buildUrl('gf/labels/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	createGFLabel(obj) {
		let url = buildUrl('gf/labels')
		return axios.post(url, obj, buildOptions()).then((res) => res.data)
	},
	updateGFLabel(obj) {
		let url = buildUrl('gf/labels')
		return axios.put(url, obj, buildOptions()).then((res) => res.data)
	},
	fetchGFLabels() {
		let url = buildUrl('gf/labels')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	manageSignupVisited(id, status) {
		let url = buildUrl('gf/courses/visited/' + id + '/' + status)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateGFCourseSettings(settings) {
		let url = buildUrl('gf/courses/settings')
		return axios.post(url, { settings }, buildOptions()).then((res) => res.data)
	},
	fetchGFCourseSettings() {
		let url = buildUrl('gf/courses/settings')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkGFCourseUsage(id) {
		let url = buildUrl('gf/courses/usage/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	uploadGFCourseImage(data) {
		let url = buildUrl('gf/courses/upload')
		return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
	},
	deleteGFCourse(id) {
		let url = buildUrl('gf/courses/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	updateGFCourse(obj) {
		let url = buildUrl('gf/courses')
		return axios.put(url, obj, buildOptions()).then((res) => res.data)
	},
	createGFCourse(obj) {
		let url = buildUrl('gf/courses')
		return axios.post(url, obj, buildOptions()).then((res) => res.data)
	},
	fetchGFCourses() {
		let url = buildUrl('gf/courses')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fixTags() {
		let url = buildUrl('/tags/fix/per/studio')
		return axios.get(url, buildOptions()).then((res) => console.log(res.data))
	},
	fetchCourseRatings(ts) {
		let url = buildUrl('gf/rating/' + ts)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	sendGFRating(rating) {
		let url = buildUrl('gf/rating')
		return axios.post(url, rating, buildOptions()).then((res) => res.data)
	},
	fetchAllGFIcons() {
		let url = buildUrl('gf/icons')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchGFIcon(icon) {
		let url = buildUrl('gf/icons/' + icon)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	downloadMarketingImageGF(format, color, courseId) {
		let url = buildUrl('gf/post/' + format + '/' + color + '/' + courseId)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	fetchTrainingDataForDay(day) {
		let url = buildUrl('user/training/day/' + day)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCompleteTrainingCalendar() {
		let url = buildUrl('user/training/complete')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchTrainingCalendar() {
		let url = buildUrl('user/training')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchUserProfileStats() {
		let url = buildUrl('user/profile/stats')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	manageUserCourseSignup(id, status, userid) {
		let url = buildUrl('gf/courses/signup/' + id + '/' + status + '/' + userid)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	manageCourseSignup(id, status) {
		let url = buildUrl('gf/courses/signup/' + id + '/' + status)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	courseSignupWithoutContract() {
		let url = buildUrl('gf/courses/without/contract/signup')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateCourseExecution(updatedCourse) {
		let url = buildUrl('gf/courses/edit/' + updatedCourse._id)
		return axios.post(url, { updatedCourse }, buildOptions()).then((res) => res.data)
	},
	lookupCoursesStudio(search) {
		let url = buildUrl('gf/courses/search/studio')
		return axios.post(url, { search }, buildOptions()).then((res) => res.data)
	},
	lookupCourses(search) {
		let url = buildUrl('gf/courses/search')
		return axios.post(url, { search }, buildOptions()).then((res) => res.data)
	},
	fetchCourseCalendar(signal = null) {
		let url = buildUrl('gf/courses/calendar')
		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	updateGFParticipantAmount(data) {
		let url = buildUrl('gf/courses/participant/amount')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchCourseTimeline(id) {
		let url = buildUrl('gf/courses/timeline/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateCourseTimeline(timeline) {
		let url = buildUrl('gf/courses/timeline')
		return axios.put(url, { timeline }, buildOptions()).then((res) => res.data)
	},
	createCourseTimeline(timeline) {
		let url = buildUrl('gf/courses/timeline')
		return axios.post(url, { timeline }, buildOptions()).then((res) => res.data)
	},
	deleteCourseTimeline(id) {
		let url = buildUrl('gf/courses/timeline/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	fetchCourseEdit(id) {
		let url = buildUrl('gf/courses/timelines/' + id + '/edit')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCourseView(id, ts) {
		let url = buildUrl('gf/courses/view/' + id + '/' + ts)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchCourseListEdit(day) {
		let url = buildUrl('gf/courses/timelines/' + day)
		return axios.get(url, buildOptions({})).then((res) => res.data)
	},
	fetchCourseList(signal, day) {
		let url = buildUrl('gf/courses/list/' + day)
		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	fetchLSList(signal, day) {
		let url = buildUrl('gf/courses/lslist/' + day)
		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	fetchCourseListSettings() {
		let url = buildUrl('gf/courses/settings')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getGFHours(month) {
		let url = buildUrl(month ? 'intern/gf/hourlist/' + month : 'intern/gf/hourlist')
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	fetchGFHours() {
		let url = buildUrl('intern/gf/hours')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchSingleCourse(id) {
		let url = buildUrl('kurse/timelines/get/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	registerForEventCourse(eventId) {
		let url = buildUrl('kurse/event/signup/' + eventId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deRegisterForEventCourse(eventId) {
		let url = buildUrl('kurse/event/signup/' + eventId)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	fetchEventCourseSignupsForUser(eventId) {
		let url = buildUrl('kurse/event/signups/' + eventId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteDevice(device) {
		let url = buildUrl('devices/' + device._id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	updateDevice(formData) {
		let url = buildUrl('devices')
		return axios.put(url, formData, buildOptions()).then((res) => res.data)
	},
	createDevice(formData) {
		let url = buildUrl('devices')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	fetchDeviceCategories() {
		let url = buildUrl('devicecategories')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchDevice(id) {
		let url = buildUrl('device/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchDevicesPage(page) {
		let url = buildUrl('devices/' + page)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchDevices(signal = null) {
		let url = buildUrl('devices')
		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	reorderExercise(deviceId, exercise) {
		let url = buildUrl('devicereorder')
		return axios.post(url, { deviceId, exercise }, buildOptions()).then((res) => res.data)
	},

	fetchAllTags() {
		let url = buildUrl('tags')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchEvent(eventId) {
		let url = buildUrl('event/' + eventId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllEvents() {
		let url = buildUrl('event')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchEventsForSlider() {
		let url = buildUrl('event/for/slider')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchEventsForSelect() {
		let url = buildUrl('event/for/select')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchEventsCount() {
		let url = buildUrl('event/count')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createEvent(formData) {
		let url = buildUrl('event')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	updateEvent(formData) {
		let url = buildUrl('event')
		return axios.put(url, formData, buildOptions()).then((res) => res.data)
	},
	deleteEvent(eventId) {
		let url = buildUrl('event/' + eventId)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	loadSortedProblems() {
		let url = buildUrl('intern/forms/problem/sorted')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchGalleries() {
		let url = buildUrl('galleries')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchImagesForGallery(galleryId) {
		let url = buildUrl('gallery/images/' + galleryId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createGallery(formData) {
		let url = buildUrl('galleries')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	updateGallery(formData) {
		let url = buildUrl('galleries')
		return axios.put(url, formData, buildOptions()).then((res) => res.data)
	},
	deleteGallery(id) {
		let url = buildUrl('galleries/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	deleteGalleryImage(image) {
		let url = buildUrl('gallery/images')
		return axios.delete(url, { data: image, headers: buildOptions() }).then((res) => res.data)
	},
	updateGalleryImageSortOrder(id, sortorder) {
		let url = buildUrl('gallery/images/' + id + '/' + sortorder)
		return axios.put(url, buildOptions()).then((res) => res.data)
	},
	fetchDisplays() {
		let url = buildUrl('display')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createDisplay(formData) {
		let url = buildUrl('display')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	updateDisplay(formData) {
		let url = buildUrl('display')
		return axios.put(url, formData, buildOptions()).then((res) => res.data)
	},
	deleteDisplay(id) {
		let url = buildUrl('display/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	loadProblems() {
		let url = buildUrl('intern/forms/problem')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitProblemForm(formData) {
		let url = buildUrl('intern/forms/problem')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	downloadListCategory(category) {
		let url = buildUrl('intern/lists/' + category + '/entries')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	downloadListCategoryPublic(category) {
		let url = buildUrl('intern/lists/' + category + '/entries/public')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkListEntry(category) {
		let url = buildUrl('intern/lists/' + category + '/entries/check')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	downloadLeadCategory(category) {
		let url = buildUrl('intern/leads/' + category + '/entries')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createLeadEntry(data) {
		if (!data.category) data.category = 'undefined'

		let url = buildUrl('intern/leads/' + data.category + '/entries')
		console.log('posting')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createListEntry(data) {
		if (!data.category) data.category = 'undefined'

		let url = buildUrl('intern/lists/' + data.category + '/entries')
		console.log('posting')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteListEntry(data) {
		if (!data.category) data.category = 'undefined'

		let url = buildUrl('intern/lists/' + data.category + '/' + data._id)

		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	loadCovidQueueAll(course_ids) {
		let url = buildUrl('kurse/covidlistall')
		return axios.post(url, { course_generate_ids: course_ids }, buildOptions()).then((res) => res.data)
	},
	loadCovidQueue() {
		let url = buildUrl('kurse/covidlist')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	removeUserFromCovidGroup(timeline_id, user_id) {
		let url = buildUrl(`covidtimelines/` + timeline_id + `/` + user_id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},
	moveFromQueueToCovidGroup(timeline_id, user_id, generate_id) {
		let url = buildUrl(`covidtimelines/` + timeline_id + `/add/` + user_id + `/` + generate_id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	signoffCovidQueue(timeline_id) {
		let url = buildUrl(`covidtimelines/leave/` + timeline_id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	fetchCovidQueueForCourse(course_id) {
		let url = buildUrl(`kurse/covidlist/` + course_id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchFrequentlyVisitingUsers(course_id) {
		let url = buildUrl(`covidtimelines/` + course_id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	copyTrainingPlan(plan_id) {
		let url = buildUrl(`plan/copy/${plan_id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserStudio() {
		let url = buildUrl('user/standort')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadCertStatus() {
		let url = buildUrl('user/cert/status')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	viewedCertCheck() {
		let url = buildUrl('user/cert/viewed/check')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fakeCert(userid) {
		let url = buildUrl('user/cert/create/' + userid)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	invalidUserCert(userid) {
		let url = buildUrl('user/cert/invalid/' + userid)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkUserCert(userid) {
		let url = buildUrl('user/cert/check/' + userid)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserCertsArchive() {
		let url = buildUrl(`user/certs/archive`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserCerts() {
		let url = buildUrl(`user/certs`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	uploadUserCert(formData) {
		let url = buildUrl(`user/cert`)
		return axios.post(url, formData, buildOptions({ headers: { 'Content-Type': 'multipart/form-data' } })).then((res) => res.data)
	},
	downloadBLSAEDZip() {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl('blsaedzip')
			try {
				const response = await axios({
					url,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'BLS_AED');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},
	uploadBLSAEDFile(formData) {
		let url = buildUrl(`upload/blsaed`)
		return axios.post(url, formData, buildOptions({ headers: { 'Content-Type': 'multipart/form-data' } })).then((res) => res.data)
	},
	downloadBLSAEDFile(fileName) {
		return new Promise(async (resolve, reject) => {
			let url = buildUrl(fileName)
			try {
				const response = await axios({
					url,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: localStorage.getItem('token')
					}
				});

				if (window.flutter_inappwebview && localStorage.getItem('isIOS')) {
					var reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						resolve(base64.toString());
					};
				} else {
					saveAs(response.data, 'BLS_AED');
					resolve(); // Resolve without a value since the download is not async
				}
			} catch (error) {
				console.log(error, "FEHLER")
				reject(error);
			}
		});
	},
	deleteCert() {
		let url = buildUrl('user/cert/delete')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	downloadCertForUser(userid) {
		let url = buildUrl('user/cert/download/' + userid)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	downloadCert() {
		let url = buildUrl('user/cert/download')
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	getUserCert() {
		let url = buildUrl('user/cert')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateTrainingsPlan(data) {
		let url = buildUrl('plan')

		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},

	updateTrainingsPlanSets(data) {
		let url = buildUrl('plan/update/sets')

		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},
	getRecentWeiterempfehlungen() {
		let url = buildUrl(`recentweiterempfehlungexcel`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getWeiterempfehlungen() {
		let url = buildUrl(`weiterempfehlungexcel`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getSensordaten() {
		let url = buildUrl(`sensordaten`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getTrainer(trainer_id) {
		let url = buildUrl(`bewertungen/kreuztabelle/trainer`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getTrainervergleich(infos) {
		let url = buildUrl(`bewertungen/kreuztabelle/studio/` + infos.studio_id + '/' + infos.von + '/' + infos.bis)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getTrainervergleich_gesamt(infos) {
		let url = buildUrl(`bewertungen/kreuztabelle/gesamt` + '/' + infos.von + '/' + infos.bis)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKreuztabelle() {
		let url = buildUrl(`bewertungen/kreuztabelle/gesamt`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getStudiovergleich() {
		let url = buildUrl(`bewertungen/studiovergleich`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getBewertungen() {
		let url = buildUrl(`bewertungen`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	hideRating() {
		let url = buildUrl(`open/ratings/hide`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	openRatingAnamnese() {
		let url = buildUrl(`open/ratings/anamnese`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	openRatingTP() {
		let url = buildUrl(`open/ratings/trainingsplan`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKursStatistik(filter) {
		let url = buildUrl(`kursstatistik/${filter.kurs_id}/${filter.ts}/${filter.ts_end}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadLSSignups(kurs_id) {
		let url = buildUrl(`livestream/signups/${kurs_id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadHeatMapRes() {
		let url = buildUrl(`heatmap`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getHeatMap(screen) {
		let url = buildUrl(`heatmap/${screen.width}/${screen.height}/${screen.variant}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitHeatMap(heatmap) {
		let url = buildUrl(`heatmap`)
		return axios.post(url, heatmap, buildOptions()).then((res) => res.data)
	},
	submitWorkoutRating(rating) {
		let url = buildUrl(`rating/trainingsplan`)
		return axios.post(url, rating, buildOptions()).then((res) => res.data)
	},
	submitAnamneseRating(rating) {
		let url = buildUrl(`rating/anamnese`)
		return axios.post(url, rating, buildOptions()).then((res) => res.data)
	},
	getKampagneInvites() {
		let url = buildUrl(`tendays/membercard/invites/download`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	gewichtshistory(id) {
		let url = buildUrl(`trainingsplan/gewichtshistory/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	ABFilter(string) {
		let url = buildUrl(`anamnesebogen/filter`)
		return axios.post(url, { filter: string }, buildOptions()).then((res) => res.data)
	},
	ABgetFilter() {
		let url = buildUrl(`anamnesebogen/filter`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	AWdownload() {
		let url = buildUrl(`wallet/generate`)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then((res) => res.data)
	},
	createFeedbackTendays(feedback) {
		let url = buildUrl(`tendays/feedback`)
		return axios.post(url, feedback, buildOptions()).then((res) => res.data)
	},
	addLSBlacklist(kurs) {
		let url = buildUrl(`livestream/blacklist/add/${kurs._id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	removeLSBlacklist(kurs) {
		let url = buildUrl(`livestream/blacklist/remove/${kurs._id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getLSBlacklist() {
		let url = buildUrl(`livestream/blacklist`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	ls_signup(kurs) {
		let url = buildUrl(`livestream/signup/${kurs._id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	ls_signoff(kurs) {
		let url = buildUrl(`livestream/signoff/${kurs._id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadLivestreamsignups() {
		let url = buildUrl('livestream/signups')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getMembercards() {
		let url = buildUrl('tendays/membercard/download')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	subscribe(subscription) {
		let url = buildUrl(`subscription`)
		return axios.post(url, { subscription: subscription }, buildOptions()).then((res) => res.data)
	},
	generateCourses() {
		let url = buildUrl('kurse/timelines/generate')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createMembercardLead(userId) {
		let url = buildUrl(`membercard/createlead/${userId}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchMembercardLeads() {
		let url = buildUrl('membercard/leads')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchPastMembercardLeads(days) {
		let url = buildUrl('membercard/leads/' + days)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchMembercardLead(userId) {
		let url = buildUrl(`membercard/fetchlead/${userId}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateMembercard(data) {
		let url = buildUrl(`membercard/user`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	startMembercard(data) {
		let url = buildUrl(`membercard/user/start`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createMembercard(id) {
		let url = buildUrl(`tendays/create/membercard/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	aboLoesen(id) {
		let url = buildUrl(`tendays/abos/loesen/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getEBTImage(email) {
		let url = buildUrl(`userebt`)
		return axios.post(url, { email: email }, buildOptions()).then((res) => res.data)
	},
	PhotoEditorUpload(data) {
		let url = buildUrl(`gseditor/upload`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	td_anmelden(id) {
		let url = buildUrl(`tendays/anmelden/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	td_aboAnfragen() {
		let url = buildUrl(`tendays/abos`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	td_buyAbo() {
		let url = buildUrl(`tendays/buy`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	td_cancelAbo() {
		let url = buildUrl(`tendays/cancel`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkCourseUsage(course) {
		let url = buildUrl(`kurse/usage/${course}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTSignOff() {
		let url = buildUrl(`terminplanerpt/signoff`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTcheck() {
		let url = buildUrl(`terminplanerpt/check`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTdeleteDate(id) {
		let url = buildUrl(`terminplanerpt/delete/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTsignUp(id) {
		let url = buildUrl(`terminplanerpt/signup/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTgetDates(studioid) {
		let url = buildUrl(`terminplanerpt/termine/${studioid}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTAddDate(date) {
		let url = buildUrl(`terminplanerpt/new`)
		return axios.post(url, date, buildOptions()).then((res) => res.data)
	},
	PTgetIntro() {
		let url = buildUrl(`terminplanerpt/introtext`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	PTEditIntro(data) {
		let url = buildUrl(`terminplanerpt/introtext`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	TPSignOff() {
		let url = buildUrl(`terminplaner/signoff`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPcheck() {
		let url = buildUrl(`terminplaner/check`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPdeleteDate(id) {
		let url = buildUrl(`terminplaner/delete/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPsignUp(id) {
		let url = buildUrl(`terminplaner/signup/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPgetDates(studioid) {
		let url = buildUrl(`terminplaner/termine/${studioid}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPAddDate(date) {
		let url = buildUrl(`terminplaner/new`)
		return axios.post(url, date, buildOptions()).then((res) => res.data)
	},
	TPgetIntro() {
		let url = buildUrl(`terminplaner/introtext`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	TPEditIntro(data) {
		let url = buildUrl(`terminplaner/introtext`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getVideoArchiv() {
		let url = buildUrl(`videoarchiv`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getVideoArchivAdmin() {
		let url = buildUrl(`videoarchivadmin`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	editVideoArchiv(va) {
		let url = buildUrl('videoarchiv')
		return axios.post(url, va, buildOptions()).then((res) => res.data)
	},
	editZoomLink(zoom) {
		let url = buildUrl('zoom')
		return axios.post(url, zoom, buildOptions()).then((res) => res.data)
	},
	getZoomLink() {
		let url = buildUrl(`zoom`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	generateAccount(data) {
		let url = buildUrl('register/generate')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getUserAnamnesebogen() {
		let url = buildUrl(`anamnesebogen/kunde`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	searchAnamnesebogen(search) {
		let url = buildUrl('anamnesebogen/search')
		return axios.post(url, { search: search }, buildOptions()).then((res) => res.data)
	},
	submitAnamnesebogen(bogen) {
		let url = buildUrl('anamnesebogen')
		return axios.post(url, bogen, buildOptions()).then((res) => res.data)
	},
	submitAdventsverlosung(fd) {
		let url = buildUrl('adventsverlosung')
		return axios.post(url, fd).then((res) => res.data)
	},

	getTimestops() {
		let url = buildUrl('timestops')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getZeitgutschriften() {
		let url = buildUrl('zeitgutschrift')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitZeitgutschrift(fd) {
		let url = buildUrl('zeitgutschrift')
		return axios.post(url, fd).then((res) => res.data)
	},
	submitTimestop(fd) {
		let url = buildUrl('timestop')
		return axios.post(url, fd).then((res) => res.data)
	},
	listAdventsverlosung() {
		let url = buildUrl('adventsverlosung')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getFriendsFeed() {
		let url = buildUrl('friend/signups')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	addFriend(email) {
		let url = buildUrl(`friend/request/add/${email}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	acceptFriend(id) {
		let url = buildUrl(`friend/request/accept/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getFriendrequests() {
		let url = buildUrl(`friend/request/list`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	removeFriend(id) {
		let url = buildUrl(`friend/decline/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getFriends() {
		let url = buildUrl(`friend/list`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCourseSettings() {
		let url = buildUrl('kurseinstellungen')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveCourseSettings(data) {
		let url = buildUrl('kurseinstellungen')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getAllExcersiceSettings(studio_id) {
		let url = buildUrl(`uebungen/einstellungen/all/${studio_id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getPlanExerciseSettings(plan_id) {
		let url = buildUrl(`uebungen/plansettings/${plan_id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getRatings(id) {
		let url = buildUrl(`ratings/get/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAllRatings(ts) {
		let url = buildUrl(`ratings/getall/${ts}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	newRating(data) {
		let url = buildUrl('ratings/new')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	checkRatingStatus(cgid) {
		let url = buildUrl(`ratings/check/${cgid}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAllSurveys() {
		let url = buildUrl('survey')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	submitUmfrage(data) {
		let url = buildUrl('survey')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	SaveExerciseSteps(data) {
		let url = buildUrl('uebungen/exercisesteps/save')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	toggleExcerciseVisibility(data) {
		let url = buildUrl('uebungen/visibility/change')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getExerciseSteps(data) {
		let url = buildUrl('uebungen/exercisesteps/get')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getTags() {
		let url = buildUrl('uebungen/tags')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getExerciseSettings(data) {
		let url = buildUrl(`uebungen/einstellungen/get/${data}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveExerciseSettings(data) {
		let url = buildUrl('uebungen/einstellungen/create')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	changeLabels(data) {
		let url = buildUrl('kurse/timelines/labelchange/')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getNews(studio) {
		let url = buildUrl(`news`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	markNewsAsRead(newsId) {
		let url = buildUrl('news/' + newsId + '/read')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getNewsCount() {
		let url = buildUrl(`news/count`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	signupCampaign(campaign_id, userid, studio_id) {
		let url = buildUrl(`campaignsignup/${campaign_id}`)
		return axios.post(url, { user_id: userid, studio_id: studio_id }, buildOptions()).then((res) => res.data)
	},
	signoffCampaign(campaign_id, userid) {
		let url = buildUrl(`campaignsignoff/${campaign_id}`)
		return axios.post(url, { user_id: userid }, buildOptions()).then((res) => res.data)
	},
	copyCampaign(campaign) {
		let url = buildUrl(`copycampaign`)
		return axios.post(url, { campaign: campaign }, buildOptions()).then((res) => res.data)
	},
	deleteCampaign(campaign) {
		let url = buildUrl(`deletecampaign`)
		return axios.post(url, { campaign: campaign }, buildOptions()).then((res) => res.data)
	},
	getKampagne(id) {
		let url = buildUrl(`kampagne/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCampaign(studio) {
		let url = buildUrl(`campaign/${studio}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCampaignList(id) {
		let url = buildUrl(`campaignlist/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAllNews(studio) {
		let url = buildUrl(`allnews`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAllCampaigns() {
		let url = buildUrl(`allcampaigns/`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveNews(news) {
		let url = buildUrl(`news`)
		return axios.put(url, { news }, buildOptions()).then((res) => res.data)
	},
	createNews(news) {
		let url = buildUrl(`news`)
		return axios.post(url, news, buildOptions()).then((res) => res.data)
	},
	createCampaign(campaign) {
		let url = buildUrl(`campaign`)
		return axios.post(url, campaign, buildOptions()).then((res) => res.data)
	},
	deleteWeiterempfehlung(id) {
		let url = buildUrl(`invites/delete/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateInvite(data) {
		let url = buildUrl('invites/update')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getInvites(data) {
		let url = buildUrl(`invites/studio/${data.filter}/${data.studio}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	searchInvites(data) {
		let url = buildUrl('invites/search')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	sendSchnuppermail(data) {
		let url = buildUrl('schnuppermail')
		return axios.post(url, data).then((res) => res.data)
	},
	getVersion() {
		let url = buildUrl('version')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	editRolle(data) {
		let url = buildUrl(`rolle`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateUserGuestEntries(data) {
		let url = buildUrl(`user/guest-entry`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	searchUserforRolle(data) {
		let url = buildUrl(`rolle/list`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchAllUsers() {
		let url = buildUrl(`fetch/users/all`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	lookupUsers(search, signal = null) {
		let url = buildUrl(`fetch/users/all`)

		return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
	},
	getUserForManager(id) {
		let url = buildUrl(`fetch/users/usersearch/` + id)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserForManagerByEmail(email) {
		let url = buildUrl(`fetch/users/usersearchemail/` + email)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getOwnUserForManager() {
		let url = buildUrl(`fetch/users/ownUser`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCustomerDashboard(id) {
		let url = buildUrl(`fetch/customer/dashboard/` + id)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getCustomerDashboardTraining(id) {
		let url = buildUrl(`fetch/customer/dashboard/training/` + id)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	lookupWorkouts(search, signal = null) {
		let url = buildUrl(`trainingsplanstudio`)

		return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
	},
	lookupInstructors(search, signal = null) {
		let url = buildUrl(`fetch/users/instructors`)

		return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
	},
	fetchInstructors() {
		let url = buildUrl(`fetch/users/instructors`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteUser(userid) {
		let url = buildUrl(`user/delete`)

		return axios.post(url, userid, buildOptions()).then((res) => res.data)
	},
	deleteOwnAccount(userid) {
		let url = buildUrl(`user/delete/self`)

		return axios.post(url, userid, buildOptions()).then((res) => res.data)
	},
	signUpUserForKurs(data) {
		let url = buildUrl(`kurse/user/signup`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteRight(data) {
		let url = buildUrl('rights/delete')
		return axios.put(url, { right: JSON.stringify(data) }, buildOptions()).then((res) => res.data)
	},
	upsertRight(data) {
		let url = buildUrl('rights')
		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},
	fetchRightsAndTopics() {
		let url = buildUrl(`rightsandtopics`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createNewRightsTopic(data) {
		let url = buildUrl(`rightstopic`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchAllRights() {
		let url = buildUrl(`rights`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllUserRoles() {
		let url = buildUrl(`user/roles`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllRoles() {
		let url = buildUrl(`roles`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateExistingUserRole(data) {
		let url = buildUrl(`roles`)
		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},
	createUserRole(data) {
		let url = buildUrl(`roles`)
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteUserRole(role_id) {
		let url = buildUrl(`roles/${role_id}`)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},

	fetchRolesForUser(userId) {
		let url = buildUrl(`user/roles/${userId}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserRights() {
		let url = buildUrl('user/rights')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserRolle() {
		let url = buildUrl('rolle')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	renewToken(signal = null) {
		let url = buildUrl('renew/token')

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	changePassword(data) {
		let url = buildUrl(`forgot/${data.key}`)

		return axios.post(url, data).then((res) => res.data)
	},
	downloadContractImageData(src, title = "") {
		let url = buildUrl('contractpicturesnew/' + src.replace("contractpicturesnew/", ""))
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'png',
						title.replaceAll(' ', '_').replaceAll('.', '_')
					)
				}
			} else {
				saveAs(response.data, title.replaceAll(' ', '_').replaceAll('.', '_'))
			}
		})
	},
	downloadContractImage(imgUrl) {
		let title = imgUrl.replace(".", "_")
		let url = buildUrl('upload/contract/view/' + imgUrl)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (response) => {
			if (window.flutter_inappwebview) {
				console.log('FLUTTER IOS DOWNLOAD PDF START')
				var reader = new FileReader()
				reader.readAsDataURL(response.data)
				reader.onloadend = function () {
					var dataUrl = reader.result
					var base64 = dataUrl.split(',')[1]
					console.log(base64)
					window.flutter_inappwebview.callHandler(
						'blobToBase64Handler',
						base64.toString(),
						'png',
						title.replaceAll(' ', '_').replaceAll('.', '_')
					)
				}
			} else {
				saveAs(response.data, title.replaceAll(' ', '_').replaceAll('.', '_'))
			}
		})


	},
	getContractImage(path) {
		let url = buildUrl('upload/contract/view/' + path)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
	},
	getTimestopImage(path) {
		let url = buildUrl('upload/timestop/view/' + path)
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
	},
	uploadContractPicture(formData) {
		let url = buildUrl('upload/contractpictures')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	saveTimestopEntry(data) {
		let url = buildUrl('timestopform')
		return axios.post(url, { entry: data }, buildOptions()).then((res) => res.data)
	},

	fetchContractImageData(src) {
		let url = buildUrl('contractpicturesnew/' + src.replace("contractpicturesnew/", ""))
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
	},
	fetchFormUpdateImage(src) {
		let url = buildUrl('formupdatepictures/' + src.replace("formupdatepictures/", ""))
		let options = {}
		options.responseType = 'blob'
		return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
	},
	uploadContractImage(formData) {
		let url = buildUrl('upload/contractpicturesnew')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	uploadFormUpdateImage(formData) {
		let url = buildUrl('upload/formupdatepictures')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	uploadTimestopImage(formData) {
		let url = buildUrl('upload/timestoppictures')
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	},
	uploadImage(data) {

		if (data.art == 'lead') {
			let url = buildUrl('upload/lead')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}

		if (data.art == 'product') {
			let url = buildUrl('upload/product')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'slide') {
			let url = buildUrl('upload/slide')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'popup') {
			let url = buildUrl('upload/popup')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art === 'timestop') {
			let url = buildUrl('upload/timestop')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'contract') {
			let url = buildUrl('upload/contract')
			console.log("upload contract")

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'kurs') {
			let url = buildUrl('upload/kurs')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'news') {
			let url = buildUrl('upload/news')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}

		if (data.art == 'uebung') {
			let url = buildUrl('upload/uebung')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'pfp') {
			let url = data._id ? buildUrl('upload/employee/' + data._id) : buildUrl('upload/pfp')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'gs') {
			let url = buildUrl('upload/gs')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'event') {
			let url = buildUrl('upload/event')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'workout') {
			let url = buildUrl('upload/workout')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'device') {
			let url = buildUrl('upload/device')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'workout') {
			let url = buildUrl('upload/workout')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'device_exercise') {
			let url = buildUrl('upload/device_exercise')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'device_exercise_manual') {
			let url = buildUrl('upload/device_exercise_manual')

			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
		if (data.art == 'groupFitness_course') {
			let url = buildUrl('upload/group_fitness_course')
			return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
		}
	},
	resetPassword(data) {
		let url = buildUrl('forgot')

		return axios.post(url, data).then((res) => res.data)
	},
	sendMail(data) {
		let url = buildUrl('mail')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	authenticate(credentials) {
		let url = buildUrl('login')

		return axios.post(url, credentials).then((res) => res.data)
	},
	saveUserRoles(users) {
		let url = buildUrl('user/edit/role')

		return axios.post(url, users).then((res) => res.data)
	},
	registerUser(credentials) {
		let url = buildUrl('register')

		return axios.post(url, credentials).then((res) => res.data)
	},
	saveConsent(customerSignature) {
		let url = buildUrl('user/consent')

		return axios.post(url, { customerSignature: customerSignature }, buildOptions()).then((res) => res.data)
	},
	saveDisclosureAggreement(customerSignature) {
		let url = buildUrl('user/non-disclosure-aggreement')

		return axios.post(url, { customerSignature: customerSignature }, buildOptions()).then((res) => res.data)
	},

	pickStudio(studio) {
		let url = buildUrl('pickstudio')

		return axios.put(url, studio, buildOptions()).then((res) => res.data)
	},
	editUserProfileFromContract(data) {
		let url = buildUrl('user/edit/profile/contract')

		return axios.put(url, data, buildOptions()).then((res) => res.data)
	},
	editUserProfile(user) {
		let url = buildUrl('user/edit/profile')

		return axios.put(url, user, buildOptions()).then((res) => res.data)
	},
	editUser(user) {
		user = JSON.parse(user)

		let url = buildUrl(`user/${JSON.parse(user.oldUser).email}`)

		return axios.put(url, JSON.parse(user.newUser), buildOptions()).then((res) => res.data)
	},
	importGeraet(data) {
		let url = buildUrl('geraete/import')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	checkGeraetbeforeImport(data) {
		let url = buildUrl('geraete/check/import')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	saveGeraet(data) {
		let url = buildUrl('geraete/save')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getGeraetebyId(id) {
		let url = buildUrl(`geraete/get/id/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getGeraetebyStudioId(studio_id) {
		let url = buildUrl(`geraete/get/${studio_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchPublicStudios() {
		let url = buildUrl(`studios/fetch/public`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchHostnames() {
		let url = buildUrl(`hosts/fetch`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchStudios() {
		let url = buildUrl(`studios/fetch`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchOpeningHours() {
		let url = buildUrl(`studios/opening-hours`)

		return axios.post(url, buildOptions()).then((res) => res.data)
	},
	saveOpeningHours(studio) {
		let url = buildUrl(`studios/save/opening-hours`)

		return axios.post(url, studio, buildOptions()).then((res) => res.data)
	},
	fetchTeamsForTitle() {
		let url = buildUrl(`teams/fetch/for/title`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getStudios() {
		let url = buildUrl(`studios/get`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUser() {
		let url = buildUrl(`user`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getLabelsByGenTimelines(gentimelines) {
		let url = buildUrl(`kurse/timelines/labels`)

		return axios.post(url, gentimelines, buildOptions()).then((res) => res.data)
	},
	loadLabels(id) {
		let url = buildUrl(`kurse/label/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getLabels(id) {
		let url = buildUrl(`label/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createLabel(label) {
		let url = buildUrl(`label`)

		return axios.post(url, label, buildOptions()).then((res) => res.data)
	},
	createRoom(room) {
		let url = buildUrl(`room`)

		return axios.post(url, room, buildOptions()).then((res) => res.data)
	},
	getUserforSignups(signups, id) {
		let data = {
			signups,
			id,
		}
		let url = buildUrl(`user/getlist/signups`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getUserById(id) {
		let url = buildUrl(`user/getbyid/` + id)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getAllUserList(signal = null) {
		let url = buildUrl(`user/getlist/all`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	getUserList() {
		let url = buildUrl(`user/getlist`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKurse() {
		let url = buildUrl(`kurse`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKursById(kursid) {
		let url = buildUrl(`kurse/get/${kursid}`)

		return axios.put(url, kursid, buildOptions()).then((res) => res.data)
	},
	createKurs(kurs) {
		let url = buildUrl(`kurse`)

		return axios.post(url, kurs, buildOptions()).then((res) => res.data)
	},
	cancelKurs(id) {
		let url = buildUrl(`timelines/cancel/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	editKurs(kurs) {
		let url = buildUrl(`kurse/${kurs._id}`)

		return axios.put(url, kurs, buildOptions()).then((res) => res.data)
	},
	deleteKurstimeline(id) {
		let url = buildUrl(`kurse/timeline/delete/${id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	deleteKurs(kurs) {
		let url = buildUrl(`kurse/delete`)
		return axios.post(url, kurs, buildOptions()).then((res) => res.data)
	},
	getInstructors() {
		let url = buildUrl(`instructors`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getRooms(id) {
		let url = buildUrl(`rooms/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKurstimelineByStudio(zeitplaene) {
		let url = buildUrl(`kurse/timeline/studio`)

		return axios.post(url, zeitplaene, buildOptions()).then((res) => res.data)
	},
	getKurstimelineById(timeline_id) {
		let url = buildUrl(`kurse/timeline/id/`)

		return axios.post(url, { timeline_id: timeline_id }, buildOptions()).then((res) => res.data)
	},
	getKurstimeline(studioid) {
		let url = buildUrl(`kurse/timeline/${studioid}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	saveKurstimeline(kurs) {
		let url = buildUrl(`kurse/timeline`)

		return axios.post(url, kurs, buildOptions()).then((res) => res.data)
	},
	editKurstimeline(kurs) {
		let url = buildUrl(`kurse/timeline/edit`)
		return axios.post(url, kurs, buildOptions()).then((res) => res.data)
	},
	getKurstimelines() {
		let url = buildUrl(`kurse/timelines`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getUserid() {
		let url = buildUrl(`userid`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKursetimelinesGeneratedbyDate(date) {
		let url = buildUrl(`kurse/timelines/generate/bydate/`)

		return axios.post(url, date, buildOptions()).then((res) => res.data)
	},

	getKursetimelinesGenerated(studioid) {
		let url = buildUrl(`kurse/timelines/generate/get/${studioid}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getKursetimelinesGeneratedEx(studioid) {
		let url = buildUrl(`kurse/timelines/generate/get/${studioid}`)

		return axios.post(url, { kursliste: true }, buildOptions()).then((res) => res.data)
	},

	generateTimelines() {
		let url = buildUrl(`kurse/timelines/generate/`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getNextKursetimelinesGenerated(studioid) {
		let url = buildUrl(`kurse/timelines/generate/get/next/${studioid}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	signUpKurs(data) {
		let url = buildUrl(`kurse/signup`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	signUpKursStatus(data) {
		let url = buildUrl(`kurse/signup/status`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	signOffKursStatus(data) {
		let url = buildUrl(`kurse/signoff`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	signOffUserKursStatus(data) {
		let url = buildUrl(`kurse/user/signoff`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getUebungbyStudioId(id) {
		let url = buildUrl(`uebungen/get/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	renderUebungen(stud_id) {
		let url = buildUrl(`uebungen/${stud_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	editTraining(trainingsplan) {
		let url = buildUrl('trainingsplan/edit')
		return axios.post(url, trainingsplan, buildOptions()).then((res) => res.data)
	},
	createTraining(trainingsplan) {
		let url = buildUrl(`trainingsplan/create`)

		return axios.post(url, trainingsplan, buildOptions()).then((res) => res.data)
	},
	fetchStudioWorkouts(signal = null) {
		let url = buildUrl(`trainingsplanstudio`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	loadTrainerWorkouts(signal = null) {
		let url = buildUrl(`workouts/studio`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	loadWorkouts() {
		let url = buildUrl(`trainingsplan/user`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getExercisesForStudio() {
		let url = buildUrl('exercises')

		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getInformationForExercise(id) {
		let url = buildUrl(`exercises/studio/${id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	createTrainingsPlan(data) {
		let url = buildUrl('plan')

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},

	loadPlanExercisesforEdit(plan_id) {
		let url = buildUrl(`trainingsplan/edit/load/exercises/${plan_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadPlanforEdit(plan_id) {
		let url = buildUrl(`trainingsplan/edit/load/plan/${plan_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	loadSignupsByUser() {
		let url = buildUrl(`kurse/user/signups`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadLSUserSignups(timeline_id) {
		let url = buildUrl(`kurse/timelines/generate/get/lssignups/${timeline_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadSignups(timeline_id) {
		let url = buildUrl(`kurse/timelines/generate/get/signups/${timeline_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	kursTeilgenommen(data) {
		let url = buildUrl(`kurse/signup/teilgenommen`)

		return axios
			.post(
				url,
				{
					ben_id: data.anmeldung.ben_id,
					course_generate_id: data.anmeldung.course_generate_id,
					teilgenommen: data.anmeldung.teilgenommen,
				},
				buildOptions()
			)
			.then((res) => res.data)
	},
	editGeneratedTimeline(data) {
		let url = buildUrl(`kurse/timelines/generate/edit`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
		plan
	},

	// Habit Routes
	getHabits(date) {
		let url = buildUrl('habits/' + date)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getHabitStatistic(habit_id) {
		let url = buildUrl('habits/statistic/' + habit_id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchWorkoutHabit(workoutId) {
		let url = buildUrl('user/training/workout/' + workoutId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchGroupFitnessHabit(courseTimelineId) {
		let url = buildUrl('user/training/groupfitness/' + courseTimelineId)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	updateHabit(data) {
		let url = buildUrl('habit')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	executeHabit(execution) {
		let url = buildUrl('habit/execute')
		return axios.post(url, execution, buildOptions()).then((res) => res.data)
	},
	deleteHabit(habitId) {
		console.log(habitId)
		let url = buildUrl('habit/' + habitId)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	},

	// Einstein Sauna Contest
	getEinsteinSaunaContestAdminList() {
		let url = buildUrl('einstein/contest/admin/list')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getEinsteinContestFreeSpaceCount() {
		let url = buildUrl('einstein/contest/availableentries')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getEinsteinContestAvailableDates() {
		let url = buildUrl('einstein/contest/availabledates')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	reserveEinsteinContestSpace(data) {
		let url = buildUrl('einstein/contest/reservespace')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateEinsteinContestEntry(data) {
		let url = buildUrl('/einstein/contest/updateentry')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	findAllEinsteinContestEntries() {
		let url = buildUrl('einstein/contest/allentries')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getEinsteinContestOwnEntry() {
		let url = buildUrl('einstein/contest/own/entry')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	// Trainingsplan Routen
	getWorkoutsForStatistics() {
		let url = buildUrl(`workouts/statistics`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	assingWorkout(data) {
		let url = buildUrl(`trainingsplan/assign`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteWorkout(data) {
		let url = buildUrl(`trainingsplan/delete`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteWorkoutById(data) {
		let url = buildUrl(`workout/delete`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createWorkoutSet(data) {
		let url = buildUrl(`trainingsplan/sets`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createWorkoutSetOld(data) {
		let url = buildUrl(`trainingsplan/sets/old`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},

	getWorkoutHistory(plan_id, exercise_id) {
		let url = buildUrl(`trainingsplan/sets/${plan_id}`)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getWorkoutSet(plan_id, exercise_id) {
		let url = buildUrl(`trainingsplan/sets/${plan_id}/${exercise_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	getWorkoutExecutions(plan_id, exercise_id) {
		let url = buildUrl(`workout/executions/${plan_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	workingOut(workoutid) {
		let url = buildUrl(`trainingsplan/workout/${workoutid}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getWorkoutNew(workoutid) {
		let url = buildUrl(`trainingsplan/workoutneu/${workoutid}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getTrainingPlan(workout_id) {
		let url = buildUrl(`workout/start/${workout_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getWorkout(workout_id) {
		let url = buildUrl(`plan/run/${workout_id}`)

		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getOldPlans(signal = null) {
		let url = buildUrl(`plan`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	getTrainingPlans(signal = null) {
		let url = buildUrl(`workouts`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	editWorkoutSettings(settings) {
		let url = buildUrl(`trainingsplan/edit/settings`)

		return axios.post(url, settings, buildOptions()).then((res) => res.data)
	},
	finishWorkout(data) {
		let url = buildUrl(`trainingsplan/done`)

		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},

	getStudioCompanies(signal = null) {
		let url = buildUrl(`studios/fetch/company`)

		return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
	},
	// Shop Routes

	fetchAllPurchases() {
		let url = buildUrl('shop/fetch/purchases')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllPayedPurchasesAdmin() {
		let url = buildUrl('shop/fetch/purchases/admin')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllPurchasesDetails() {
		let url = buildUrl('shop/fetch/purchases/details')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	checkPaymentStatus(id) {
		let url = buildUrl('shop/check/payment/status/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	itemSavedInTAC(data) {
		let url = buildUrl('shop/saved/code/to/tac')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getPaymentInfos(id) {
		let url = buildUrl('shop/get/payment/infos/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	createOrUpdateProduct(data) {
		let url = buildUrl('shop/product/create')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchProductsManagement() {
		let url = buildUrl('shop/fetch/products/management')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchProductsPublicStore(data) {
		let url = buildUrl('shop/fetch/products/public/store')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	createProductPayment(data) {
		let url = buildUrl('shop/product/buy/and/create/payment')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	downloadRealVoucher(data) {
		let url = buildUrl('shop/product/real/printversion')
		return axios.post(url, data,
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}).then((response) => {
				if (window.flutter_inappwebview) {
					console.log('FLUTTER IOS DOWNLOAD PDF START')
					var reader = new FileReader()
					reader.readAsDataURL(response.data)
					reader.onloadend = function () {
						var dataUrl = reader.result
						var base64 = dataUrl.split(',')[1]

						window.flutter_inappwebview.callHandler(
							'blobToBase64Handler',
							base64.toString(),
							'pdf',
							'wellComeFitGutschein'
						)
					}
				} else {
					saveAs(response.data, 'gutscheinAusdruck')
				}
			})
	},
	productExamplePrintVersion(data) {
		let url = buildUrl('shop/product/example/printversion')
		return axios.post(url, data,
			{
				responseType: 'blob',
				headers: {
					Authorization: localStorage.getItem("token")
				}, // important
			}).then((response) => {
				if (window.flutter_inappwebview) {
					console.log('FLUTTER IOS DOWNLOAD PDF START')
					var reader = new FileReader()
					reader.readAsDataURL(response.data)
					reader.onloadend = function () {
						var dataUrl = reader.result
						var base64 = dataUrl.split(',')[1]

						window.flutter_inappwebview.callHandler(
							'blobToBase64Handler',
							base64.toString(),
							'pdf',
							'wellComeFitGutschein'
						)
					}
				} else {
					saveAs(response.data, 'beispielGutschein')
				}
			})
	},
	updateNutritionShoppingList(data) {
		let url = buildUrl('nutrition/update/shoppinglist')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateNutritionShoppingListFullTableItems(data) {
		let url = buildUrl('nutrition/update/shoppinglist/fullTableItems')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	fetchNutritionUserShoppingList() {
		let url = buildUrl('nutrition/fetch/shoppingList')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchWeekNutritionStatistics(date) {
		let url = buildUrl('nutrition/fetch/weekly/stats/' + date)
		return axios.get(url, buildOptions()).then((res) => res.data)

	},
	fetchOwnNutritionAnamnese() {
		let url = buildUrl('anamnesebogen/nutrition/existing')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllAvailableFormUpdateForUser(habit) {
		let url = buildUrl('fetch/available/form/update/for/' + habit)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	setNutritionDayChoice(data) {
		let url = buildUrl('nutrition/set/day/choice')
		return axios.post(url, data, buildOptions()).then((res) => res.data)

	},

	saveNutritionPortionSizes(data) {
		let url = buildUrl('nutrition/add/portion/sizes')
		return axios.post(url, data, buildOptions()).then((res) => res.data)

	},
	fetchNutritionDataForDay(day) {
		let url = buildUrl('nutrition/data/for/day/' + day)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllRecipesForDate(day) {
		let url = buildUrl('nutrition/fetch/all/recipes/for/' + day)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchAllRecipesShoppingList(day) {
		let url = buildUrl('nutrition/fetch/all/recipes/shopping-list')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	nutritionForUserExists() {
		let url = buildUrl('nutrition/for/user/exists')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	searchNutritionApi(data) {
		let url = buildUrl('nutrition/search')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getUserNutritionFavorites() {
		let url = buildUrl('fetch/user/nutrition/favorites')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	addRecipeToNutritionFavorites(recipe) {
		let url = buildUrl('add/recipe/to/favorites')
		return axios.post(url, recipe, buildOptions()).then((res) => res.data)
	},
	getReceiptNutritionApi(id, calories = 0) {
		let url = buildUrl('nutrition/search/' + id + '/' + calories)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	getReceiptByFilter(data) {
		let url = buildUrl('nutrition/receipt/by/filter')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	getNutritionSearchFilters() {
		let url = buildUrl('nutrition/get/search/filters')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createNutritionDashboard(data) {
		let url = buildUrl('create/new/dashboard/for/user')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	saveMealPlanToNutritionUser(data) {
		let url = buildUrl('/save/mealplan')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateMealPlanAllergens(data) {
		let url = buildUrl('/update/mealplan/allergens')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	updateMealPlanDiet(data) {
		let url = buildUrl('/update/mealplan/diet')
		return axios.post(url, data, buildOptions()).then((res) => res.data)
	},
	deleteMealPlan(id) {
		let url = buildUrl('/delete/mealplan/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},

	loadCustomerDashboardCoach(id) {
		let url = buildUrl('load/existing/dashboard/for/user/coach/' + id)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	loadNutritionUser() {
		let url = buildUrl('load/nutrition/user')
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	toggleLaunchNutritionStatus() {
		let url = buildUrl('toggle/launch/nutrition/status')
		return axios.get(url, buildOptions()).then((res) => res.data)

	},
	saveSelfCheckinEntryEditTODB(data) {
		let url = buildUrl('save/self/checkin/entry/edit/to/db')
		return axios.post(url, data, buildOptions()).then((res) => res.data)

	},
	addNewNutritionEntry(data) {
		let url = buildUrl('nutrition/add/user/entry')
		return axios.post(url, { data }, buildOptions()).then((res) => res.data)
	},
	askNutritionAi(message) {
		let url = buildUrl('nutrition/ai')
		return axios.post(url, { message }, buildOptions()).then((res) => res.data)
	},
	searchOpenDatabase(search, page) {
		let url = buildUrl('search/openfood/for/products/' + search + '/' + page)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	fetchOpenDatabaseBarcode(code) {
		let url = buildUrl('search/openfood/by/barcode/' + code)
		return axios.get(url, buildOptions()).then((res) => res.data)
	},
	createOpenFoodProduct(body) {
		let url = buildUrl('/create/openfood/new/prodct')

		let options = buildOptions()
		options.headers['Content-Type'] = 'multipart/form/data'

		return axios.post(url, body, buildOptions()).then((res) => res.data)
	},
	uploadWebsiteImage(formData) {
		let url = buildUrl('website/upload/image')
		let options = buildOptions()
		options.headers['Content-Type'] = 'multipart/form-data'
		return axios.post(url, formData, buildOptions()).then((res) => res.data)
	}
}

