<template>
    <div v-if="authenticated" class="Home">
        <!---<v-img height="60px" width="60px" class="rounded" :src="'/img/profilbilder/' + user.bild"></v-img>-->
        <div class="Home__greeting" v-if="false">
            <v-card-subtitle class="Home__greeting-subtitle text-black">Hey {{ user.vorname }},</v-card-subtitle>
            <v-card-title v-if="!alibeyStudio || alibeyDate <= 0" class="Home__greeting-title h6 pb-1">
                Bist Du bereit für
                <br />
                dein heutiges
                <span class="Home__greeting-title-training">Training</span>
                ?
            </v-card-title>
            <v-card-title v-else class="Home__greeting-title h6 pb-1">bald ist es soweit ...</v-card-title>

            <div v-if="!alibeyStudio" class="Home__greeting-gift">
                <v-img @click="pushToReferralRoute()" height="90px" width="90px" src="/img/icons/giftGreen.gif"></v-img>
            </div>
        </div>
        <div class="Home__diverse mt-5 pt-2">
            <diverse-slider data-v-step="1"></diverse-slider>
        </div>
        <v-row class="mt-2 mb-5" v-if="alibeyStudio">
            <v-col class="pb-0" cols="12">
                <vue-countdown
                    v-if="alibeyDate > 0"
                    :time="alibeyDate"
                    v-slot="{ days, hours, minutes, seconds }"
                    class="countdownAlibey"
                >
                    <div class="tage date">
                        <span class="up">
                            {{ days }}
                        </span>
                        <span class="down">Tage</span>
                    </div>
                    <div class="stunden date">
                        <span class="up">
                            {{ hours }}
                        </span>
                        <span class="down">Stunden</span>
                    </div>
                    <div class="minuten date">
                        <span class="up">
                            {{ minutes }}
                        </span>
                        <span class="down">Minuten</span>
                    </div>
                    <div class="sekunden date">
                        <span class="up">
                            {{ seconds }}
                        </span>
                        <span class="down">Sekunden</span>
                    </div>
                </vue-countdown>
            </v-col>
        </v-row>
        <div>
            <payment-home @foundOpenPayments="openPayments = true"></payment-home>
        </div>

        <v-row>
            <v-col cols="12" class="d-flex mb-2 mt-5">
                <v-btn outlined color="primary" @click="$router.push('/alibey')" class="mx-auto">
                    Jetzt Sportwoche buchen
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="!alibeyStudio && !hideMyTraining" class="Home__training">
            <my-training data-v-step="0"></my-training>
        </div>

        <v-row class="personalTraining" v-if="false && alibeyStudio">
            <v-btn x-large class="white--text mx-auto py-4 my-5" color="#ffa500" @click="$router.push('/terminept')">
                <span class="py-5">
                    Dein
                    <br />
                    Willkommensgeschenk
                </span>
            </v-btn>
        </v-row>

        <membercard
            :firstName="user.vorname"
            :email="user.email"
            @hideTraining="hideMyTraining = true"
            :membercard="user.membercard"
        ></membercard>
        <router-view></router-view>

        <router-link
            v-if="tendays_date != null && tendays_date > 0 && !user.abo"
            to="/membercard"
            tag="div"
            class="link"
        >
            <div class="membercard-wrapper">
                <div class="countdown-wrapper">
                    <span class="time_left">Verbleibende Zeit</span>
                    <vue-countdown :time="tendays_date" v-slot="{ days, hours, minutes, seconds }" class="countdown">
                        <div class="tage">
                            <span class="up">
                                {{ days }}
                            </span>
                            <span class="down">TAGE</span>
                        </div>
                        <div class="stunden">
                            <span class="up">
                                {{ hours }}
                            </span>
                            <span class="down">STUNDEN</span>
                        </div>
                        <div class="minuten">
                            <span class="up">
                                {{ minutes }}
                            </span>
                            <span class="down">MINUTEN</span>
                        </div>
                        <div class="sekunden">
                            <span class="up">
                                {{ seconds }}
                            </span>
                            <span class="down">SEKUNDEN</span>
                        </div>
                    </vue-countdown>
                </div>

                <span class="mcard-span">MEMBERCARD</span>
            </div>
        </router-link>

        <div class="Home__groupFitness pb-5 mb-10"><next-courses-home data-v-step="2"></next-courses-home></div>
    </div>
</template>

<script>
import api from '../api'
import nextCourses from '../components/gf/nextCourses.vue'
import nextCoursesHome from '../components/gf/nextCoursesHome.vue'
import livestreams from '../components/gf/livestreams.vue'
import newsSlider from '../components/news/newsSlider.vue'
import myTraining from '../components/training/myTraining.vue'
import diverseSlider from '../components/home/diverseSlider.vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import PaymentHome from './dashboard/payment/PaymentHome.vue'
import membercard from '@/components/home/membercard.vue'

import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'Home',
    components: {
        VueCountdown,
        diverseSlider,
        nextCourses,
        nextCoursesHome,
        newsSlider,
        myTraining,
        livestreams,
        PaymentHome,
        membercard,
    },
    data() {
        return {
            hideMyTraining: false,
            openPayments: false,
            hideLSStudio: true,
            tendays_date: null,
        }
    },
    mounted() {
        if (
            localStorage.getItem('token') &&
            !localStorage.getItem('tourFinished') &&
            (!localStorage.getItem('tourSkipped') || this.checkTime(localStorage.getItem('tourSkipped')) > 23)
        ) {
            this.$emit('showTour')
        }
    },
    async created() {
        if (localStorage.getItem('token')) {
            if (!localStorage.getItem('pushToken') && window.flutter_inappwebview) {
                //|| this.checkTime(localStorage.getItem('initPushCooldown')) > 23
                if (!localStorage.getItem('initPushCooldown')) {
                    this.$emit('showPushPopUp')
                }
            }
            this.$emit('checkPopup')
            this.$emit('checkVersion')
            this.$emit('fetchNotifications')
            this.setNavigationBarText(null)

            this.setNewBottomNavigationUsage(null)
        }

        // ToDo: vllt chli geiler mache aber er fetched nachm logout -> login de user ned neu wills i de app.vue isch

        console.log('set user')
        api.getUser()
            .then((u) => {
                // if (!u.user.hideLandingPage) this.$router.push({ name: 'Landing' })

                this.setUser(u.user)

                localStorage.setItem('user', JSON.stringify({ user: u.user }))
                if (
                    !u.user.studio_id ||
                    u.user.studio_id === 'frauenfeld' ||
                    u.user.studio_id === 'livestreams' ||
                    u.user.studio_id === 'ot'
                ) {
                    this.hideLSStudio = true
                } else {
                    this.hideLSStudio = false
                }
                if (u.user.tendays_date) {
                    let now = new Date(this.user.tendays_date * 1000)
                    let end = now.setDate(now.getDate() + 10)
                    now = new Date()

                    this.tendays_date = end - now
                }
                if (u.user.consent1 && !this.navigationVisible) {
                    this.toggleNavigationBarVisibility()
                }
            })
            .catch((e) => {
                this.$router.push('/login')
            })
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
        ...mapState('userStore', ['user']),
        local() {
            return location.host.includes('local')
        },
        alibeyDate() {
            let now = this.$moment().unix() * 1000
            let end = this.$moment('2023-05-27 10:00').unix() * 1000

            return end - now
        },
        alibeyStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null

            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
                return true
            } else {
                return false
            }
        },

        authenticated() {
            if (localStorage.getItem('token')) return true
            return false
        },
        roodyGeneral() {
            if (
                location.host.includes('wellcomefit') ||
                location.host.includes('localhost') ||
                location.host.includes('wintifit')
            )
                return true
            return false
        },
    },

    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'setNewBottomNavigationUsage',
            'toggleNavigationBarVisibility',
        ]),
        ...mapActions('userStore', ['setUser']),
        pushToReferralRoute() {
            this.$router.push('/referral')
            /* if (
				this.user &&
				(this.user.email === 'dominic.ammann.ch@gmail.com' ||
					this.user.email === 'sebs@wellcomefit.ch' ||
					this.user.email === 'tk@wellcomefit.ch' ||
					this.user.email === 'jw@wellcomefit.ch' ||
					this.user.email === 'kn@wellcomefit.ch')
			) {
				this.$router.push('/einstein/contest')
			} else {
				this.$router.push('/intern/referral')
			} */
        },

        checkTime(item) {
            var currentTime = this.$moment()
            var timeStore = this.$moment(item.created)

            return currentTime.diff(timeStore, 'h')
        },
    },
}
</script>

<style lang="scss" scoped>
.countdownAlibey {
    display: flex;
    flex-direction: row;
    width: 305px;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .date {
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        text-align: center;

        .up {
            font-weight: bold;
            font-size: 28px;
            color: #355a83;
        }

        .down {
            font-size: 14px;
            text-transform: uppercase;
            color: #355a83;
            letter-spacing: 1.1px;
        }
    }
}
.membercard-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;

    .countdown-wrapper {
        margin: auto 0 0 0;
        position: absolute;
        top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .time_left {
            text-transform: uppercase;
            font-weight: bold;
            color: #fff;
            padding: 5px 0px 5px 0px;
            font-size: 13px;
            letter-spacing: 2px;
            text-align: center;
            background-color: #ff9800;
            margin-bottom: 7px;
        }
        .countdown {
            display: flex;
            flex-direction: row;
            width: 305px;
            margin: auto;
            > div {
                margin: 10px;
                display: flex;
                flex-direction: column;
            }
            span.up {
                text-align: center;
            }
            span.down {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }

    .mcard-span {
        margin: auto;
        padding-top: 100px;
    }
}
.section {
    position: relative;
}

.tag {
    display: flex;
    justify-content: space-between;

    .tag_title {
        font-size: 18px;
        letter-spacing: 0.5px;
    }

    .tag_subtitle {
        font-size: 14px;
        margin: auto 0px;
    }
    span {
        font-weight: bold;
    }
}

.Home {
    &__diverse {
        width: 91.666%;
        margin: 0 auto;
    }
    &__greeting {
        display: block;
        position: relative;

        &-gift {
            position: absolute;
            right: 0;
            top: 0;
        }
        &-referral {
            height: 110px;
            width: 160px;
            background-size: contain;
            background-image: url(/img/kampagne/refer/referAFriend100.png);
            position: absolute;
            top: 0px;
            right: 0px;
        }

        &-subtitle {
            color: black !important;
            letter-spacing: 1px;
            font-weight: bold;
            padding-bottom: 0px;
        }
        &-title {
            letter-spacing: 1px;
            padding-top: 0px;
            display: block;
            font-weight: bold;
            &-training {
                color: orange;
            }
        }
    }
}
</style>
